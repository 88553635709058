import React from "react"
import { graphql } from "gatsby"
import ReactPlayer from "react-player"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Zoom from "react-medium-image-zoom"
import "react-medium-image-zoom/dist/styles.css"
import Layout from "../components/Layout"
import InternalLayout from "../components/InternalLayout"
import Head from "../components/Head"
import projectStyle from "./project.module.scss"

export const query = graphql`
  query($slug: String!) {
    contentfulProjectPage(slug: { eq: $slug }) {
      title
      year(formatString: "YYYY")
      job {
        name
        description
      }
      body {
        json
      }
    }
  }
`

const ProjectPage = props => {
  const options = {
    renderNode: {
      "embedded-asset-block": node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        if (node.data.target.fields.file["en-US"].contentType === "video/mp4") {
          return (
            <div className={projectStyle.theatre}>
              <div className="player-wrapper">
                <ReactPlayer
                  className="react-player fixed-bottom"
                  url={url}
                  width="100%"
                  height="700px"
                  controls={true}
                />
              </div>
            </div>
          )
        } else {
          return (
            <div className={projectStyle.theatre}>
              <Zoom>
                <img alt={alt} src={url} />
              </Zoom>
            </div>
          )
        }
      },
    },
  }

  return (
    <Layout>
      <Head title={props.data.contentfulProjectPage.title} />
      <InternalLayout>
        <div className={projectStyle.container}>
          <div className={projectStyle.year}>
            <span>{`${props.data.contentfulProjectPage.year} / ${props.data.contentfulProjectPage.job.name}`}</span>
          </div>
          <h1 className={projectStyle.title}>
            {props.data.contentfulProjectPage.title}
          </h1>
          {props.data.contentfulProjectPage.body &&
            documentToReactComponents(
              props.data.contentfulProjectPage.body.json,
              options
            )}
        </div>
      </InternalLayout>
    </Layout>
  )
}

export default ProjectPage
